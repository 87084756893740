import CardMessage from '../../../../../components/CardMessage'
import iconDocLock from '../../../../../assets/images/icon_document_lock.svg'

export default {
  name: 'DraftSuccess',

  data() {
    return {
      iconDocLock,
    }
  },

  components: {
    CardMessage,
  },
}
